import { Redirect, Route } from 'react-router-dom';
import { ProtectedRoute } from '../../components/atoms';
import { HomeScreen } from '../../screens';

const homeScreens = [
  <Route
    exact
    path="/"
    render={() => {
      return <Redirect to="/home" />;
    }}
  />,
  <ProtectedRoute path="/home" exact component={HomeScreen} />,
];

export const AppRouter = [homeScreens];

export const AppModalFormsRouter = [];
