import React from 'react';
import { Route } from 'react-router-dom';
import _ from 'lodash';

import { ProtectedRouteProps } from '../../../types';

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  routeToAuthenticate,
  ...rest
}) => {
  if (Component) {
    return (
      <Route
        {...rest}
        render={(props) => {
          return (
            <Component {...props} loading={_.get(routeToAuthenticate, 'isLoading', false)} /> )
        }}
      />
    );
  }
  return null;
};
