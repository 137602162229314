import React from 'react';
import { Link, useHistory } from 'react-router-dom';

export const Footer = () => {
  const history = useHistory();

  const ButtonMailto = ({ mailto, label }) => {
    return (
      <Link
        to="#"
        onClick={(e) => {
          window.location.href = mailto;
          e.preventDefault();
        }}
      >
        <p className="text-blue-400 hover:text-blue-400">{label}</p>
      </Link>
    );
  };
  const _home = () => {
    document.getElementById('home').scrollIntoView({
      behavior: 'smooth',
    });
  };

  const _about = () => {
    document.getElementById('about').scrollIntoView({
      behavior: 'smooth',
    });
  };

  const _projects = () => {
    document.getElementById('services').scrollIntoView({
      behavior: 'smooth',
    });
  };

  const _plans = () => {
    document.getElementById('projects').scrollIntoView({
      behavior: 'smooth',
    });
  };

  const _contact = () => {
    document.getElementById('contact').scrollIntoView({
      behavior: 'smooth',
    });
  };

  const linkStyle = 'hover:cursor-pointer';
  const InstagramLogo = require('../../../assets/images/instagram.png');
  const facebookLogo = require('../../../assets/images/facebook.png');
  const TwitterLogo = require('../../../assets/images/twitter.png');
  const TikTokLogo = require('../../../assets/images/tiktok.png');
  const Logo = require('../../../assets/images/xencode.png');

  return (
    <>
      <div className="text-white bg-xencode-contact-black border-t-2 border-xencode-gold">
        <div className="md:grid grid-cols-4 grid-flow-col gap-3 lg:px-72 px-4 py-24">
          <div>
            <a onClick={_home}>
              <img src={Logo} alt="xencode" className="w-36 hover:cursor-pointer ml-[-10px]" />
            </a>
            <h1 className="font-bold text-xl text-xencode-gold md:mt-2">Services</h1>
            <ul className="space-y-2">
              <li>Startup Business Web Design</li>
              <li>Website Rebuilds / Upgrades</li>
              <li>Ecommerce</li>
              <li>Mobile App</li>
            </ul>
          </div>
          <div>
            <h1 className="font-bold text-xl md:mt-24 mt-8 text-xencode-gold">Links</h1>
            <ul className="space-y-2">
              <li className={linkStyle}>
                <a onClick={_home}>Home</a>
              </li>
              <li className={linkStyle}>
                <a onClick={_plans}>Services</a>
              </li>
              <li className={linkStyle}>
                <a onClick={_projects}>Projects</a>
              </li>
              {
                <li className={linkStyle} onClick={_about}>
                  About
                </li>
              }
              <li className={linkStyle} onClick={_contact}>
                Contact
              </li>
            </ul>
          </div>
          <div>
            <h1 className="font-bold text-xl md:mt-24 mt-8 text-xencode-gold">Social Media</h1>
            <ul className="inline-flex space-x-2">
              <li>
                <a href="https://www.facebook.com/profile.php?id=100090099461564">
                  <img src={facebookLogo} alt="Facebook" className="w-8 hover:cursor-pointer" />
                </a>
              </li>
              <li>
                <a href="https://www.instagram.com/xencode__/">
                  <img src={InstagramLogo} alt="Instagram" className="w-8 hover:cursor-pointer" />
                </a>
              </li>
              <li>
                <img
                  src={TwitterLogo}
                  alt="Twitter"
                  className="w-8 hover:cursor-pointer opacity-40"
                />
              </li>
              <li>
                <img
                  src={TikTokLogo}
                  alt="TikTok"
                  className="w-8 hover:cursor-pointer opacity-40"
                />
              </li>
            </ul>
          </div>
          <div>
            <h1 className="font-bold text-xl md:mt-24 mt-8 text-xencode-gold">Support</h1>
            <ul>
              <li className="overflow-hidden">
                <ButtonMailto label="info@xencode.co.za" mailto="mailto:info@xencode.co.za" />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="lg:h-8 h-12 bg-black lg:px-72 px-4 md:grid grid-cols-2 grid-flow-col gap-4 justify-center pt-2 text-xs">
        <div className="text-gray-500">
          <p className="text-gray-500">© COPYRIGHT 2024. ALL RIGHTS RESERVED</p>
        </div>
        <div className="md:text-right text-left">
          <p className="text-gray-500">
            WEBSITE DESIGN BY <span className="text-xencode-gold">XenCode</span>
          </p>
        </div>
      </div>
    </>
  );
};
