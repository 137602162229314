import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import emailjs from 'emailjs-com';
import { useForm } from 'react-hook-form';

import config from '../../../../config';

export const ContactForm = () => {
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const toastifySuccess = () => {
    toast('Message sent! We will get back to you shorlty.', {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: 'submit-feedback success',
      toastId: 'notifyToast',
    });
  };

  const onSubmit = async (data) => {
    const { name, email, subject, message } = data;
    setIsLoading(true);

    try {
      const templateParams = {
        name,
        email,
        subject,
        message,
      };

      await emailjs.send(config.serviceId, config.templateId, templateParams, config.userId);

      reset();
      toastifySuccess();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
    }
  };

  const inputStyle = 'p-3 rounded-md mb-3 text-black';
  const errorStyle = 'text-xs text-red-600';

  return (
    <div className="pb-12">
      <div className="flex-1">
        <form id="contact-form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <div className="pb-3 md:pb-0 grid w-full">
            <label className="text-white text-left">Name*</label>
            <input
              type="text"
              name="name"
              {...register('name', {
                required: { value: true, message: 'Please enter your name' },
                maxLength: {
                  value: 30,
                  message: 'Please use 30 characters or less',
                },
              })}
              className={inputStyle}
            />
            {errors.name && <p className={errorStyle}>{errors.name.message}</p>}
          </div>
          <div className="grid w-full">
            <label className="text-white text-left">Email*</label>
            <input
              type="email"
              name="email"
              {...register('email', {
                required: true,
                pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
              })}
              className={inputStyle}
            />
            {errors.email && <p className={errorStyle}>Please enter a valid email address</p>}
          </div>
          <div className="pb-3">
            <div className="w-full grid">
              <label className="text-left">Plan*</label>
              <select
                name="subject"
                {...register('subject', {
                  required: { value: true, message: 'Please enter a subject' },
                })}
                className={inputStyle}
              >
                <option value="plan 1">Plan 1</option>
                <option value="plan 2">Plan 2</option>
                <option value="plan 3">Plan 3</option>
                <option value="plan 4">Plan 4</option>
              </select>
              {errors.subject && <span className={errorStyle}>{errors.subject.message}</span>}
            </div>
          </div>
          <div>
            <div className="w-full grid">
              <label className="text-white text-left">Message*</label>
              <textarea
                rows={3}
                name="message"
                {...register('message', {
                  required: true,
                })}
                className={inputStyle}
              />
              {errors.message && <span className={errorStyle}>Please enter a message</span>}
            </div>
          </div>
          <div className="flex justify-end">
            <button
              disabled={isLoading}
              className={`p-2 mt-8 ${
                isLoading ? 'opacity-50' : 'opacity-100'
              } text-xencode-gold font-semibold border-xencode-gold hover:text-white border-2 rounded-3xl px-12 outline-none hover:border-white`}
              type="submit"
            >
              Submit
            </button>
          </div>
          <div className="text-white text-center mt-4">
            <ToastContainer />
          </div>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
