import { useEffect, useRef, useState } from 'react';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import AOS styles
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

import { AppLayout, ContactForm, ScrollView } from '../../../components';

const HomeBackground = require('../../../assets/images/home.png');
const djFlava = require('../../../assets/images/dj_flava_final.png');
const sourcFromSport = require('../../../assets/images/source_from_sport.png');
const xencode = require('../../../assets/images/xencode_web.png');
const gigabite = require('../../../assets/images/gigabite_final.png');
const Map = require('../../../assets/images/map.png');
const briefcase = require('../../../assets/images/about-circle.png');
const hands = require('../../../assets/images/contact-circle.png');
const getinTouch = require('../../../assets/images/get-in-touch.png');
const customers = require('../../../assets/images/customers.png');

const ScrollToTop = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const openWhatsApp = () => {
    const phoneNumber = '+27691302407';
    window.open(`https://wa.me/${phoneNumber}`, '_blank');
  };

  return (
    <div className="fixed md:bottom-10 bottom-10 right-5 md:right-20 flex gap-2 z-50">
      <div onClick={scrollToTop}>
        <FontAwesomeIcon
          className="bg-xencode-contact-black text-white rounded-full md:p-3 p-5 hover:bg-xencode-gold hover:cursor-pointer shadow-md border-[1px] border-xencode-gold"
          icon={faArrowUp}
        />
      </div>

      <div onClick={openWhatsApp}>
        <FontAwesomeIcon
          className="bg-green-500 text-white rounded-full md:p-3 p-5 hover:bg-green-600 hover:cursor-pointer shadow-md"
          icon={faWhatsapp}
        />
      </div>
    </div>
  );
};

const CompanyInfo = ({ image, title, text }: { image: any; title: string; text: string }) => (
  <div className="max-w-4xl md:mx-auto mx-8 grid grid-cols-1 md:grid-cols-2 justify-center grid-flow-row gap-8 my-10" data-aos="fade-up">
    <div className="text-center md:col-span-1 place-items-center w-full md:justify-start justify-center flex">
      <img src={image} alt="Briefcase" className="w-56 h-auto text-center place-items-center" />
    </div>
    <div className="md:text-left text-center md:col-span-1">
      <div className="mx-auto">
        <h1 className="break-words text-base font-bold md:text-base mb-4 mr-2 text-xencode-black tracking-wider">
          {title}
        </h1>
        <p className="break-words font-semi-bold text-sm md:text-base mt-4 lg:mt-2 text-xencode-black tracking-wider">
          {text}
        </p>
      </div>
    </div>
  </div>
);

const RenderServicePlan = ({
  title,
  subtitle,
  details,
}: {
  title: string;
  subtitle: string;
  details: any;
}) => (
  <div className="mt-4 rounded-tl-md rounded-tr-md rounded-bl-md rounded-br-md shadow-md hover:scale-105 bg-xencode-contact-black" data-aos="fade-up">
    <h1 className="text-center font-bold mb-4 bg-xencode-black text-xencode-gold rounded-tl-md rounded-tr-md p-4">
      {title}
    </h1>
    <h1 className="mx-2 text-center font-bold text-xencode-gold">{subtitle}</h1>
    <ul className="p-4">
      {details.map((detail, index) => (
        <li key={index} className="text-center mt-2 font-semi-bold text-white">
          {detail}
        </li>
      ))}
    </ul>
  </div>
);

const RenderTestamonial = ({ client, details }: { client: string; details: any }) => (
  <div className="mt-4 rounded-tl-md rounded-tr-md rounded-bl-md rounded-br-md shadow-md hover:scale-105 bg-xencode-contact-black" data-aos="fade-down">
    <p className="text-center mt-2 text-white p-6 text-sm italic">"{details}"</p>
    <p className="text-center text-white p-6 text-sm italic">-- {client} --</p>
  </div>
);

interface Project {
  title: string;
  link: string;
  image: string;
}

interface InfiniteCarouselProps {
  items: Project[];
}

const InfiniteCarousel: React.FC<InfiniteCarouselProps> = ({ items }) => {
  return (
    <div className="carousel-container py-8 overflow-x-hidden">
      <div className="carousel-wrapper flex">
        <div className="carousel-inner flex animate-scroll lg:animate-scroll md:animate-scroll-md sm:animate-scroll-sm gap-6 py-8">
          {items.concat(items).map((item, index) => (
            <div
              className="carousel-item flex-shrink-0 w-80 sm:w-60 md:w-64 lg:w-80 h-48"
              key={index}
            >
              <a href={item.link}>
                <img
                  className="hover:opacity-70 shadow-lg rounded-lg w-full h-full object-cover"
                  src={item.image}
                  alt={item.title}
                />
                <h2 className="text-center mt-1">{item.title}</h2>
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};


const projects = [
  { title: 'DJ Flava', link: 'https://www.djflavainternational.com/home', image: djFlava },
  { title: 'Gigabite', link: 'https://gigabite-web.herokuapp.com', image: gigabite },
  { title: 'Source From Sport', link: 'https://pivotal-web-070e9f3f9d48.herokuapp.com/home', image: sourcFromSport },
  { title: 'Xencode', link: 'https://www.xencode.co.za', image: xencode },
];

export const HomeScreen = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      easing: 'ease-in-out', // Easing function
      once: false, // Whether animation should happen only once - while scrolling down
    });
  }, []);

  const handleGetQuoteClick = () => {
    document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
  };

  const handleContactMeClick = () => {
    document.getElementById('contact').scrollIntoView({ behavior: 'smooth' });
  };

  const [isLoading, setIsLoading] = useState(false);

  const getQuoteButtonRef = useRef(null);

  const handleScroll = () => {
    const buttonElement = getQuoteButtonRef.current;
    const servicesSection = document.getElementById('services');
    const homeSection = document.getElementById('home');

    if (buttonElement && servicesSection && homeSection) {
      const servicesOffsetTop = servicesSection.offsetTop;
      const homeOffsetTop = homeSection.offsetTop;

      const scrollTop = window.scrollY;

      if (
        window.innerWidth >= 768 &&
        scrollTop >= homeOffsetTop &&
        scrollTop <= servicesOffsetTop
      ) {
        buttonElement.style.top = `${scrollTop - homeOffsetTop}px`;
      } else {
        buttonElement.style.top = '';
      }

      if (window.innerWidth < 768 && scrollTop >= homeOffsetTop) {
        buttonElement.style.position = 'fixed';
        buttonElement.style.bottom = '60px';
      } else {
        buttonElement.style.position = '';
        buttonElement.style.bottom = '';
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <AppLayout toggle="home">
      <div className="fixed md:top-32 md:left-24 md:block hidden h-10 z-50">
        <button
          ref={getQuoteButtonRef}
          disabled={isLoading}
          className={`font-semibold bg-xencode-contact-black text-white rounded-md p-3 hover:bg-xencode-gold shadow-2xl animate-pulse`}
          onClick={handleGetQuoteClick}
        >
          Get a Quote
        </button>
      </div>
      <ScrollToTop />

      <div id="home" className="px-4 py-48 bg-xencode-black relative">
        <img
          src={HomeBackground}
          alt="Background"
          className="absolute inset-0 w-full h-full object-cover md:object-cover"
        />
        <div className="max-w-4xl mx-auto relative z-10 text-center md:text-left text-white">
          <h1 className="text-4xl mb-6" data-aos="fade-in">
            Welcome to <span className="text-xencode-gold font-abeakrg">Xencode</span>
          </h1>
          <p className="break-words text-sm md:text-base mt-2 ml-2 font-poppins tracking-wider">
            Welcome to the xencode family, your brand's window to the world. Our team at xencode
            provides start-up or established businesses with world-class software development
            services, ensuring that your online presence is prominent and flamboyant.
          </p>
        </div>
      </div>

      <div id="services" className="px-4 py-12 bg-xencode-gold text-center md:text-left text-white" >
        <div className="max-w-4xl mx-auto">
          <h1 className="text-4xl mb-6">
            OUR<span className="text-4xl text-xencode-black font-abeakrg ml-4" data-aos="fade-down">SERVICES</span>
          </h1>
          <p className="break-words text-sm md:text-base mt-2 font-poppins tracking-wider text-white" data-aos="fade-up">
            Add a `plan` as a topic on the contact us page,
            <br /> and we will respond as soon as possible
          </p>
        </div>
      </div>

      <div className="px-2 py-20 bg-white">
        <div className="max-w-4xl mx-auto md:grid grid-cols-1 md:grid-cols-3 justify-center grid-flow-row gap-3">
          <RenderServicePlan
            title="PLAN 1"
            subtitle="Start-up and Business Design"
            details={[
              'Custom Front-end Development',
              'Free start Consultation',
              'Set up Server',
              'Maintenance 1 month',
            ]}
          />
          <RenderServicePlan
            title="PLAN 2"
            subtitle="Website Rebuild / Upgrade"
            details={[
              'Redesign Prototype',
              'Website Overhaul',
              'ReactJs/Javscript',
              'Maintenance 1 month',
            ]}
          />
          <RenderServicePlan
            title="PLAN 3"
            subtitle="Ecommerce"
            details={[
              'Custom Front-end Development',
              'Custom Back-end Development',
              'Architectural Diagram',
              'Design Prototypes',
              'Database Setup',
              'Add Payment Gateway',
              'Set up Server',
              'Free Consultation',
              'Maintenance for 2 months',
            ]}
          />
          <RenderServicePlan
            title="PLAN 4"
            subtitle="Mobile App"
            details={[
              'Custom Front-end Development',
              'Custom Back-end Development',
              'Architectural Diagram',
              'Design Prototypes',
              'Database Setup',
              'Add Payment Gateway',
              'Set up Server',
              'Free Consultation',
              'Maintenance for 2 months',
            ]}
          />
        </div>
      </div>

      <section className="h-96 bg-business bg-fixed bg-cover overflow-auto flex z-20 md:mr-24 md:rounded-tr-[200px] md:rounded-br-[200px]" data-aos="fade-right">
        <div className="text-xl font-bold text-white self-center justify-center text-center w-full relative">
          <ScrollView>
            <div className="max-w-4xl mx-auto relative text-center md:text-left text-white px-2">
              <h1 className="text-4xl mb-6 md:ml-10">
                CONNECT WITH <span className="text-xencode-gold font-abeakrg">US</span>
              </h1>
              <p className="text-sm md:text-base mt-2 md:ml-10">
                Add a `plan` as a topic on the contact us page, and we will respond as soon as
                possible
              </p>
              <div className="mt-6 text-center md:text-right md:mr-24">
                <button
                  className="bg-xencode-gold text-white px-8 py-3 rounded-md md:ml-4 hover:bg-xencode-black mt-8"
                  onClick={handleContactMeClick}
                >
                  Contact Us
                </button>
              </div>
            </div>
          </ScrollView>
        </div>
      </section>

      <section className="px-4 py-10 bg-white">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-3xl mb-12">
            Our<span className="text-3xl text-xencode-gold font-abeakrg ml-4">Clients</span>
          </h1>
          <img src={Map} alt="analysis" className="w-full bg-white" data-aos="fade-in" />
          <p className="break-words text-sm md:text-base my-2 text-xencode-black font-poppins tracking-wider mt-8" data-aos="fade-up">
            At Xencode, we make it our sole priority to ensure your ambitions are reached. Xencode
            would like to let your brand reach its full potential by introducing your brand to the
            world. Xencode works hand in hand with clients, ensuring standards and client
            satisfaction are met with the end goal being long term relationships with clients.
          </p>
        </div>
      </section>

      <div className="bg-xencode-black w-full h-24">
        <div className="w-full h-24 bg-white md:rounded-b-[100px] rounded-b-[50px]" />
      </div>

      <section id="projects" className="overflow-hidden">
        <div className="px-4 py-24 bg-xencode-black overflow-hidden">
          <div className="max-w-4xl mx-auto text-center md:text-left text-white">
            <h1 className="text-3xl mb-6">
              Our<span className="text-3xl text-xencode-gold font-abeakrg ml-4">Projects</span>
            </h1>
            <p className="break-words text-sm md:text-base mt-2 tracking-wider text-white">
              Explore some of our previous work
            </p>
          </div>
        </div>
      </section>

      <InfiniteCarousel items={projects} />

      <section
        id="about"
        className="px-4 py-24 bg-aboutscroll bg-cover bg-fixed relative md:rounded-tr-[100px] bg-xencode-gold"
      >
        <div className="text-xl font-bold text-white self-center justify-center text-center w-full relative">
          <ScrollView>
            <div className="max-w-4xl mx-auto relative z-10 text-center md:text-left text-white">
              <h1 className="text-4xl mb-6" data-aos="fade-right">
                About<span className="text-4xl text-xencode-gold font-abeakrg ml-2">Us</span>
              </h1>
              <p className="break-words text-sm md:text-base mt-2 tracking-wider" data-aos="fade-up">
                Xencode was found to provide a service to connect
                <br />
                start-up and established businesses to the world. We
                <br />
                create the most admirable online presence for your <br />
                business to give you the best possible chance to
                <br />
                attract potential clients from all over the world.
              </p>
            </div>
          </ScrollView>
        </div>
      </section>

      <div className="top-0 left-0 w-full px-4 py-12 bg-xencode-gold relative">
        <div className="max-w-4xl mx-auto text-center md:text-left text-xencode-black">
          <h1 className="text-2xl mb-6 text-xencode-black mt-8">
            What can <span className="text-white font-abeakrg mr-2">Xencode</span>
            <b />
            for your business
          </h1>
          <div className="mt-6 text-center md:text-right">
            <button
              className="bg-xencode-black text-white px-8 py-3 rounded-md md:ml-2 hover:bg-xencode-contact-black my-8"
              onClick={handleGetQuoteClick}
            >
              Get a Quote
            </button>
          </div>
        </div>
      </div>

      <div className="bg-xencode-gold  w-full h-24">
        <div className="w-full h-24 bg-white md:rounded-tl-[100px] rounded-tl-[50px]" />
      </div>

      <CompanyInfo
        image={briefcase}
        title="A website makes your business / brand look proffesional"
        text={
          'Close to 90% of customers assume a website makes your business more credible than companies who just have social media profiles. Furthermore, your website will be the perfect place to show off all your credentials, awards and professional certifications your business has. With cyber crimes increasing rapidly and internet scams affecting so many consumers, potential clients would not trust a business that does not have a physical address and website. Displaying details is proof that you are a legitimate business.'
        }
      />
      <CompanyInfo
        image={hands}
        title="You can visually showcase your products and services"
        text="Potential clients love seeing what you have to offer before making a purchase. First impressions last, that's what a well designed website assures you of and a big bonus is adding your brand vision, mission, core values, history,portfolio reviews and images of your staff and products."
      />
      <CompanyInfo
        image={customers}
        title="A website while encourage customers to contact you"
        text="The best place to make your contact infomation easliy avaible to poteintal customers will definitely be your website. Xencode will ensure that your contact information is on every single page, either in a header or footer. Furthermore, XENCODE will also make it easy for customers to contact you by creating a contact form, which can protect your business email from spam"
      />

      <div className="bg-xencode-gold  w-full h-24">
        <div className="w-full h-24 bg-white md:rounded-br-[100px] rounded-br-[50px]" />
      </div>

      <div className="px-2 py-20 bg-xencode-gold">
        <div className="max-w-4xl mx-auto text-center md:text-left text-white">
          <h1 className="text-3xl mb-6" data-aos="fade-right">
            Our<span className="text-3xl text-xencode-black font-abeakrg ml-4">Testamonials</span>
          </h1>
          <p className="break-words text-sm md:text-base tracking-wider text-white">
            here is some client feedback
          </p>
        </div>
        <div className="max-w-4xl mx-auto md:grid grid-cols-1 md:grid-cols-2 justify-center grid-flow-row gap-3">
          <RenderTestamonial
            client="Danielle"
            details={
              'Working with Xencode to create my website was an incredible experience. They truly understood my vision and brought it to life with a design that exceeded my expectations. Their attention to detail, creativity, and seamless communication made the entire process smooth and stress-free. I couldn’t be happier with the final result—it’s professional, user-friendly, and perfectly aligned with my brand. Highly recommend Xencode for anyone looking to build a standout online presence!'
            }
          />
          <RenderTestamonial
            client="Flava"
            details={
              'I approached Xencode to build a website for my business and the level of service I received was beyond my expectations. I would definitely recommend Xencode to everyone!'
            }
          />
        </div>
      </div>

      <div className="px-4 py-12 bg-xencode-gold w-full">
        <div className="text-center max-w-4xl mx-auto md:text-center text-white w-full">
          <h1 className="text-3xl mb-2 text-white">
            A website is your brand's window to the world !!!!
          </h1>
          <h1 className="text-3xl mb-2 text-white">
            <span className="text-xencode-black font-abeakrg mr-2">Xencode</span>
            is here to create the window for your business
          </h1>
        </div>
      </div>

      <div id="contact" className="w-full px-4 md:px-0 py-10 mx-auto bg-xencode-black relative">
        <div className="max-w-4xl mx-auto text-center md:text-left text-white md:flex lg:justify-between">
          <div className="w-full h-full">
            <img src={getinTouch} alt="background" className="bg-transparent w-96 justify-center" />
          </div>
          <div className="md:w-full">
            <ContactForm />
          </div>
        </div>
      </div>
    </AppLayout>
  );
};
