/* eslint-disable jsx-a11y/anchor-is-valid */
import { ReactElement, ReactNode, useState } from 'react';

export const Nav = ({ children }: { children: ReactNode; toggle: string }): ReactElement => {
  const [navbar, setNavbar] = useState(false);
  const Logo = require('../../../assets/images/xencode.png');
  const navStyle = 'text-white hover:text-xencode-gold hover:cursor-pointer font-abeakrg';

  const _home = () => {
    document.getElementById('home').scrollIntoView({
      behavior: 'smooth',
    });
  };

  const _about = () => {
    document.getElementById('about').scrollIntoView({
      behavior: 'smooth',
    });
  };

  const _plans = () => {
    document.getElementById('services').scrollIntoView({
      behavior: 'smooth',
    });
  };

  const _projects = () => {
    document.getElementById('projects').scrollIntoView({
      behavior: 'smooth',
    });
  };

  const _contact = () => {
    document.getElementById('contact').scrollIntoView({
      behavior: 'smooth',
    });
  };

  const _navbarChange = () => setNavbar(!navbar);

  const ButtonMailto = ({ mailto, label }) => {
    return <a href={mailto}>{label}</a>;
  };

  return (
    <div className="h-screen w-screen z-50 relative shadow-md">
      <nav className="w-full fixed z-50 bg-xencode-contact-black shadow-md ">
        <div className="justify-between px-4 mx-auto lg:px-24 md:items-center md:flex md:px-8">
          <div>
            <div className="flex items-center justify-between md:block">
              <a onClick={_home}>
                <img src={Logo} alt="xencode" className="w-36 hover:cursor-pointer ml-[-10px]" />
              </a>
              <div className="md:hidden">
                <button
                  className="p-2 text-gray-700 rounded-md outline-none focus:border-white focus:border"
                  onClick={_navbarChange}
                >
                  {navbar ? (
                    <svg className="w-6 h-6" viewBox="0 0 20 20" fill="white">
                      <path
                        fillRule="evenodd"
                        d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                        clipRule="evenodd"
                      />
                    </svg>
                  ) : (
                    <svg
                      className="w-6 h-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="white"
                      strokeWidth={2}
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4 6h16M4 12h16M4 18h16"
                      />
                    </svg>
                  )}
                </button>
              </div>
            </div>
          </div>
          <div className="z-50">
            <div
              className={`flex-1 justify-self-center md:block md:mt-0 z-20 ${
                navbar ? 'block' : 'hidden'
              }`}
            >
              <ul className="items-center justify-center space-y-8 md:flex md:space-x-6 md:space-y-0 md:mb-0 mb-6 ml-2">
                <li className={navStyle}>
                  <a onClick={_home}>Home</a>
                </li>
                <li className={navStyle}>
                  <a onClick={_plans}>Services</a>
                </li>
                <li className={navStyle}>
                  <a onClick={_projects}>Projects</a>
                </li>
                <li className={navStyle}>
                  <a onClick={_about}>About</a>
                </li>
                <li className={navStyle}>
                  <a onClick={_contact}>Contact</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="bg-xencode-gold h-6 w-full md:block hidden">
          <div className="px-4 mx-auto lg:px-24 flex md:px-8 justify-between w-full text-white">
            <p className="justify-end flo">
              email us at:{' '}
              <ButtonMailto label="info@xencode.co.za" mailto="mailto:info@xencode.co.za" />
            </p>
            <p className="justify-end flo">
              call us at: <a href="tel:+27691302407">+27 69 130 2407</a>{' '}
            </p>
          </div>
        </div>
      </nav>
      <div className="mt-16 relative">{children}</div>
    </div>
  );
};
